import React, { useEffect, useState } from "react";
import css from "./index.module.scss";
import Link from "next/link";
import site from "/scripts/apis/site/index.js";
import LogoSvg from "public/images/logos/lazyPrompts/sealTextHorizontal.svg";
import gsap from "scripts/gsap/index.js";
import MorphSVGPlugin from "scripts/gsap/MorphSVGPlugin";
import BurgerSvg from "/public/images/icons/burger.svg";
import {useRouter} from "next/router";
import { useUser } from "scripts/apis/site/useUser";


export default function Header({minimal = false}) {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("/");
  const user = useUser({refreshInterval: 10000});
  const router = useRouter();

  useEffect(() => {
    gsap.registerPlugin(MorphSVGPlugin);
    setUrl(window.location.href);
  }, []);

  let links = [
    {
      row: "primary",
      url: "/listings",
      label: "Shop",
      visible: () => true,
      active: () => router.pathname.startsWith("/listings"),
    },
    {
      row: "primary",
      url: "/articles",
      label: "News",
      visible: () => true,
      active: () => router.pathname.startsWith("/articles"),
    },
    {
      row: "primary",
      url: "/landing/sell",
      label: "Sell Prompts",
      visible: () => true,
      active: () => router.pathname.startsWith("/landing/sell"),
    },
    {
      row: "primary",
      url: "/account",
      label: "Account",
      visible: () => user?.value?.id,
      active: () => router.pathname === "/profile",
    },
    {
      row: "primary",
      url: "/auth/signin",
      label: "Sign In",
      visible: () => !user?.value?.id,
      active: () => router.pathname === "/auth/signin",
    },
    {
      row: "primary",
      url: "/account/cart",
      label: "Cart",
      visible: () => true,
      active: () => router.pathname === "/cart",
    },
  ];

  let headerClassName = css.header;
  if (isOpen){
    headerClassName += ` ${css.open}`;
  }

  return (
    <header className={headerClassName}>
      <div className={`sectionInner ${css.sectionInner}`}>
        {!minimal && <Link href="/"><a aria-label="Lazy Prompts Home" className={`custom ${css.logo}`}><LogoSvg/></a></Link>}
        {minimal && <div className={css.logo}><LogoSvg/></div>}
        {!minimal &&
        <nav className={css.menu}>
          <div className={css.links}>
            {links.filter((link) => link.row === "primary" && link.visible())
              .map((link) => <Link key={link.label} href={link.url}><a className={`${css.navItem} ${link.active() ? css.active : ""}`}>{link.label}</a></Link>)
            }
          </div>
          <button className={css.burger} onClick={() => {
            if (isOpen === false) {
              setIsOpen(null);
              gsap.fromTo(`.${css.header} .${css.navItem}`, {
                translateX: "100%",
              }, {
                translateX: 0,
                ease: "sine.out",
                duration: 0.3,
                stagger: {
                  amount: 0.4,
                },
              });

              //morph the burger button
              gsap.fromTo(".icon.burger .burgerOpen", { morphSVG: ".icon.burger .burgerOpen" }, { morphSVG: ".icon.burger .burgerClosed", duration: 0.5, ease: "sine.out" });

              //allow user to close the menu
              setTimeout(() => {
                setIsOpen(true);
              }, 550); //total time of the nav sliding animation
            } else if (isOpen === true){
              setIsOpen(null);
              let tl = gsap.fromTo(`.${css.header} .${css.navItem}`, {
                translateX: "0%",
              }, {
                translateX: "100%",
                ease: "sine.in",
                duration: 0.3,
                stagger: {
                  amount: 0.2,
                  from: "end",
                },
                clearProps: "transform",
              });

              //morph the burger button
              gsap.fromTo(".icon.burger .burgerOpen", { morphSVG: ".icon.burger .burgerClosed" }, { morphSVG: ".icon.burger .burgerOpen", duration: 0.5, ease: "sine.in" });

              //allow user to open the menu again
              setTimeout(() => {
                setIsOpen(false);
              }, 550); //total time of the nav sliding animation
            }
          }} aria-label="Hamburger menu" ><BurgerSvg /></button>

        </nav>}
      </div>
    </header>
  );
}
