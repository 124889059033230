import useSWR from "swr";
import strapi from "/scripts/apis/strapi/index.js";
import { getCookie } from "scripts/utils";

export function useUser(swrOpts, options = {}) {
  const url = strapi.endpoints.USER_ME;
  const fetcher = () => {
    if (getCookie("jwt")){
      return new Promise(async(resolve, reject) => {
        let res = await strapi.query(url, options);
        if (!res.error){
          resolve(res);
        } else {
          if (res.error !== 403){
            console.error(res);
          }
          resolve({});
        }
      });
    }
    return {};
  };
  const { data, error, mutate } = useSWR(url + JSON.stringify(options), fetcher, swrOpts);

  return {
    value: data, //data object
    loading: !error && !data, //isLoading
    error, //error value
    mutate,
  };
}

