import qs from "qs";
import {getCookie} from "/scripts/utils/index.js";

const endpoints = {
  USER_ME: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/users/me`},
  USER_ME_CHECKOUT: {method: "POST", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/users/me/checkout`},
  USERS_ME_GET_CONNECT_LINK: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/users/me/get-connect-link`},
  USERS_ME_GET_DASHBOARD_LINK: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/users/me/get-dashboard-link`},
  USERS_ME_UPDATE: {method: "PUT", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/users/me/update`},
  USERS_PUT: {method: "PUT", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/users/${data.id}`},

  STORES_FIND_MANY: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/stores`},
  STORES_FIND: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/stores/${data.id}`},


  ARTICLES_FIND: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/articles`},
  ARTICLES_FIND_ONE: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/articles/${data.id}`},

  LISTINGS_FIND: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/listings`},
  LISTINGS_FIND_ONE: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/listings/${data.id}`},
  LISTINGS_ME_FIND_ONE: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/listings/me/${data.id}`},
  LISTINGS_ME_CREATE: {method: "POST", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/listings/me`},
  LISTINGS_ME_UPDATE: {method: "PUT", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/listings/me/${data.id}`},
  LISTINGS_ME_DELETE: {method: "DELETE", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/listings/me/${data.id}`},
  LISTINGS_SEARCH: {method: "POST", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/listings/search`},

  TAGS_FIND: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/tags`},

  AI_MODELS_FIND: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/ai-models`},

  UPLOAD_FILE: {method: "POST", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/upload`},

  PURCHASE_ME_FIND_ONE: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/purchases/me/${data.id}`},
  PURCHASES_ME_CREATE: {method: "POST", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/purchases/me`},

  SHOUTBOX_MESSAGE_CREATE: {method: "POST", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/shoutbox-messages`},
  SHOUTBOX_MESSAGE_FIND: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${process.env.NEXT_PUBLIC_API_URL}/api/shoutbox-messages`},


};

function query(endpoint, {
  sort = undefined,
  populate = undefined,
  filters = undefined,
  fields = undefined,
  data = {},
  auth = "",
  key = "",
  pagination = undefined,
  body = null,
  publicationState = "live",
}){
  let url = endpoint.url({sort, populate, filters, data, auth, key});

  let settings = {
    method: endpoint.method,
    credentials: "include",
  };
  let jwt = auth?.jwt;

  if (!jwt){
    jwt = getCookie("jwt")?.replace(/"/g, "");
  }

  if (jwt || key){
    settings.headers = {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${key || jwt}`,
    };
  }

  //if (endpoint.method === "GET"){
  const queryString = qs.stringify({populate, sort, filters, publicationState, pagination, fields}, {encodeValuesOnly: true});
  url += `?${queryString}`;
  //}

  if (endpoint.method === "PUT"){
    settings.body = JSON.stringify(data);
  } else if (endpoint.method === "POST"){
    settings.body = JSON.stringify({data});
  }

  //user defined a custom body
  if (body){
    settings.headers = {
      "Accept": "application/json, text/plain, */*",
      "Authorization": `Bearer ${key || jwt}`,
    };
    settings.body = body;
  }

  return fetch(url, settings)
    .then((res) => res.json())
    .then((res) => {
      if (res.error){
        console.error("Strapi error:", res.error);
      }
      if (res.data){
        return res.data;
      }
      return res;
    });
}

const ex = {
  endpoints,
  query,
};
export default ex;
