export function getCookie(name) {
  if (typeof window !== "undefined"){
    var nameEQ = `${name }=`;
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
  }
  return null;
};
export function setCookie(name, value, days = 30) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${ date.toUTCString()}`;
  }
  document.cookie = `${name }=${ value || "" }${expires }; path=/`;
};



const initialCart = {
  version: process.env.NEXT_PUBLIC_VERSION,
  listings: {},
};

export function getCart(){
  let cart = JSON.parse(localStorage.getItem("cart")) || {};
  if (cart.version !== process.env.NEXT_PUBLIC_VERSION){
    cart = JSON.parse(JSON.stringify(initialCart));
  }
  return cart;
}

export function addToCart(listing){
  let cart = JSON.parse(localStorage.getItem("cart")) || {};
  if (cart.version !== process.env.NEXT_PUBLIC_VERSION){
    cart = JSON.parse(JSON.stringify(initialCart));
  }

  cart.listings[listing.id] = listing;
  localStorage.setItem("cart", JSON.stringify(cart));

  return cart;
}


export function removeFromCart(id){
  let cart = JSON.parse(localStorage.getItem("cart")) || {};
  if (cart.version !== process.env.NEXT_PUBLIC_VERSION){
    cart = JSON.parse(JSON.stringify(initialCart));
  }

  if (cart.listings[id]){
    delete cart.listings[id];
  }

  localStorage.setItem("cart", JSON.stringify(cart));

  return cart;
}

export function sendDatalayerEvent(event, category, data){
  if (!window.dataLayer){
    window.dataLayer = [];
  }

  try {
    window.dataLayer.push({
      "event": event,
      "eventCategory": category,
      ...data,
    });
  } catch (err){
    console.error(err);
  }
}
