const endpoints = {
  AUTH_SIGN_OUT: "/api/auth/local/sign-out",
  AUTH_REGISTER: "/api/auth/local/register",
  AUTH_SIGN_IN: "/api/auth/local/signin",
  AUTH_FORGOT: "/api/auth/local/forgot",
  AUTH_RESET: "/api/auth/local/reset",
  AUTH_ME: "/api/auth/local/me",

  STRIPE_GET_DASHBOARD_LINK: "/api/stripe/get-dashboard-link",
  STRIPE_GET_CHECKOUT_LINK: "/api/stripe/get-checkout-link",
};

function query(endpoint, data){
  return fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
}

const ex = {
  endpoints,
  query,
};
export default ex;
