import React from "react";
import css from "./index.module.scss";
import Link from "next/link";
import LogoSvg from "public/images/logos/lazyPrompts/sealTextHorizontal.svg";
import LogoSealSvg from "public/images/logos/lazyPrompts/seal.svg";

export default function Footer({ auth, minimal = false }) {
  return (
    <footer className={`${css.footer} ${minimal ? css.minimal : ""}`}>
      <div className={`sectionInner ${css.sectionInner}`}>
        <div className={css.top}>
          <div className={css.info}>
            <div className={css.logo}><LogoSvg /></div>
            <div className={css.blurb}>Lazy Prompts is the leading marketplace for buying and selling enterprise AI prompts. Leverage the power of AI, one prompt at a time.</div>
          </div>

          {!minimal && <div className={css.links}>
            <div className={css.label}>Links</div>
            <Link href="/contact">Contact</Link>
            <a href="https://support.lazyprompts.com">Support</a>
            <Link href="/faq">FAQ</Link>
            <Link href="/about">About</Link>
          </div>}
          {!minimal && <div className={css.links}>
            <div className={css.label}>Legal</div>
            <Link href="/legal/terms-and-conditions">Terms of Service</Link>
            <Link href="/legal/privacy-policy">Privacy Policy</Link>
            <Link href="/legal/author-terms">Author Terms</Link>
            <Link href="/legal/refund-policy">Refund Policy</Link>
            <Link href="/legal/submission-guidelines">Submission Guidelines</Link>
          </div>}
        </div>
        <div className={css.separator}>
          <LogoSealSvg/>
        </div>
        <div className={css.bottom}>
          <div className={css.copyright}>Copyright © {(new Date()).getFullYear()} Lazy Prompts</div>

          {!minimal && <div className={css.copyright}>Created by <a className="custom" href="https://deviatelabs.com">Deviate Labs</a>.</div>}
          {minimal && <div className={css.copyright}>Created by Deviate Labs.</div>}

          {!minimal && <div className={css.copyright}><a className="custom" href="mailto:hello@lazyprompts.com">hello@lazyprompts.com</a></div>}
          {minimal && <div className={css.copyright}><a className="custom" href="mailto:hello@lazyprompts.com">hello@lazyprompts.com</a></div>}
        </div>
      </div>
    </footer>
  );
}



/*
nav links
Contact
Legal
copyright
faq
support ticket
*/
